<template>
    <div class="all-panel">

        <div style="width: 100%;text-align: left;box-sizing: border-box;padding: 120px 25px 0;">
            <div style="display: flex;align-items: center">
                <img :src="logoUrl" style="width: 40px;height: 40px;">
                <span style="font-size: 28px;font-weight: bold;margin-left: 15px;">{{ this.webName }}</span>
            </div>
            <div style="font-size: 16px;color: #666666; text-align: left;margin-top: 8px;">
                <span>自助充值</span>
            </div>
        </div>
        <div class="main-page">
            <van-form ref="form" class="form-panel" validate-first @submit="onSubmit">

                <van-field v-model="account" label="账号" placeholder="请输入账号" autocomplete="off" required
                           @blur="checkAccount"
                           :rules="[{ required: true, message: ''},{ pattern : accountPattern, message: '账号为6-12位字母数字或下划线组成'}]"/>

                <van-field v-model="uid" label="UID" placeholder="请输入UID" autocomplete="off" required type="digit"
                           @blur="checkUid"
                           :rules="[{ required: true, message: ''}]"/>

                <van-field v-model="amount" type="number" required label="充值金额" placeholder="请输入充值金额"
                           :rules="[{ required: true, message: '' },{ pattern: /^-?\d+([.]\d{1,2})?$/, message: '充值金额格式错误' }]"/>

                <van-field v-model="game_name" required right-icon="arrow" label="充值游戏" placeholder="请选择充值游戏"
                           :rules="[{ required: true, message: ''}]" readonly @click="showPicker"/>

                <van-popup v-model="show" position="bottom">
                    <van-picker
                        title="请选择充值游戏"
                        show-toolbar
                        :columns="gameNameList"
                        @confirm="onConfirm"
                        @cancel="show = false"
                    />
                </van-popup>
                <div class="tips-panel">
                    <span>温馨提示：虚拟产品，一经充值无法退还</span>
                </div>
                <div class="button-panel">
                    <van-button type="info" block round native-type="submit" :loading="submitting" style="height: 40px;margin-top: 30px;font-size: 14px;"
                                loading-text="充值中"
                                color="#00adef">
                        确认充值
                    </van-button>
                </div>
            </van-form>

            <div class="diy-dialog" v-show="diyDialogShow">
                <div style="text-align: center">提示</div>
                <van-divider/>
                <div style="height: 80px">您将打开支付页面，请问是否继续？<br><br>
                    <span style="color: #b3b3b6; font-size: 13px;">注：支付成功后请关闭新打开的支付窗口</span>
                </div>
                <van-divider/>
                <div class="diy-dialog-buttons">
                    <div>
                        <a @click="diyDialogShow = false">取消</a>
                    </div>
                    <div>
                        <a target="_blank" :href="payUrl" @click="diyDialogShow = false">继续支付</a>
                    </div>
                </div>
            </div>
        </div>
        <van-overlay :show="diyDialogShow"/>
    </div>

</template>

<script>
import {qlOrder, wxAuth, get} from '@/api/request'
import {Toast} from 'vant'
import '@vant/touch-emulator';
import webConfig from "@/api/config";

export default {
    name: 'UserRecharge',
    data() {
        return {
            accountPattern: /^[a-zA-Z0-9_]{6,20}$/,
            submitting: false,
            gameList: [],
            gameNameList: [],
            show: false,
            payType: '',
            account: '',
            uid: '',
            confirmAccount: '',
            game_name: '',
            game_id: '',
            amount: '',
            diyDialogShow: false,
            payCodeShow: false,
            payCodeImgShow: false,
            payUrl: '',
            openid: '',
            logoUrl: '',
            redirectUri: '',
            appid: '',
            coinName: '平台币',
            webName: '',
        }
    },
    mounted() {
        this.logoUrl = webConfig.logoUrl;
        this.coinName = webConfig.coinName;
        this.redirectUri = webConfig.redirectUri;
        this.appid = webConfig.appid;
        this.webName = webConfig.appName;

        let openid = localStorage.getItem("open");
        if (openid) {
            this.openid = openid;
        } else {
            let url = window.location.href;
            let arr = url.split("#")[0];
            if (arr.indexOf('?') > -1 && arr.indexOf('&') > -1) {
                arr = arr.split("?")[1].split("&");
                let obj = {};
                for (let i = 0; i < arr.length; i++) {
                    obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
                }
                let params = {
                    code: obj.code
                }
                this.authWx(params);
            } else {
                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?" +
                    "appid=" + this.appid +
                    "&redirect_uri=" + encodeURIComponent(this.redirectUri) +
                    "&response_type=code" +
                    "&scope=snsapi_base" +
                    "&state=" + new Date().getTime() +
                    "#wechat_redirect"
            }
        }
    },
    methods: {
        authWx(params) {
            wxAuth(params).then(res => {
                if (res.data.success) {
                    localStorage.setItem("open", res.data.data.openid)
                    this.openid = res.data.data.openid;
                    location.href = this.redirectUri
                } else {
                    Toast("充值异常");
                }
            })
        },
        reset() {
            this.amount = '';
        },
        checkAccount() {
            this.queryGameList(1, this.account);
        },
        checkUid() {
            this.queryGameList(2, this.uid);
        },
        queryGameList(type, value) {
            if (!value) {
                return;
            }
            let url = "/open/checkAndGetPlayGame?checkType=" + type + "&checkValue=" + value;
            get(url).then(res => {
                if (res.data.success) {
                    if (type === 1) {
                        let uid = res.data.data.userId + "";
                        if (this.uid !== uid) {
                            this.uid = uid;
                            this.gameList = res.data.data.gameList;
                            this.makeData();
                        }
                    } else {
                        let account = res.data.data.userName;
                        if (this.account !== account) {
                            this.account = account;
                            this.gameList = res.data.data.gameList;
                            this.makeData();
                        }
                    }

                } else {
                    if (type === 1) {
                        this.uid = '';
                    } else {
                        this.account = '';
                    }
                    Toast(res.data.error.message);
                    this.gameList = [];
                    this.game_name = '';
                }
            })
        },
        makeData() {
            let first = this.gameList[0];
            this.game_name = first.game_name;
            this.game_id = first.game_id;

            this.gameNameList = [];
            this.gameList.forEach((e, i) => {
                this.gameNameList.push(e.game_name);
            });
        },
        onSubmit() {
            if (!this.account || !this.uid) {
                Toast('请先完善账号信息');
                return;
            }
            if (this.amount < 1) {
                Toast('充值金额不能小于1');
                return;
            }
            if (!this.game_id) {
                Toast('请选择充值游戏');
                return;
            }
            this.submitting = true;
            let params = {
                userName: this.account,
                gameId: this.game_id,
                money: this.amount,
                paymentType: 5,
                openid: this.openid
            };
            qlOrder(params).then(res => {
                this.submitting = false;
                if (res.data.success) {
                    console.log('支付成功');
                    this.payUrl = res.data.data.payString;
                    let ua = navigator.userAgent.toLowerCase();
                    if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
                        this.diyDialogShow = true;
                    } else {
                        window.open(this.payUrl, '');
                    }
                } else {
                    Toast(res.data.error);
                }
            });
        },
        onConfirm(value, index) {
            this.game_name = value;
            this.game_id = this.gameList[index].game_id;
            this.show = false;
        },
        showPicker() {
            if (!this.account || !this.uid) {
                Toast('请先完善账号信息');
                return;
            }
            if (this.gameList.length === 0) {
                Toast('暂未查询到玩过的游戏');
                return;
            }
            this.show = true;
        },
        validateSameAccount() {
            return new Promise((resolve) => {
                if (!this.account || !this.confirmAccount) {
                    resolve(true);
                }
                if (this.account !== this.confirmAccount) {
                    resolve(false);
                }
                resolve(true);

            });
        },
    }
}
</script>

<style scoped lang="scss">
.all-panel {
    width: 100%;
    background-image: url(../../assets/main-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    height: 100vh;

    .header-panel {
        width: 100%;
        height: 70px;
        background-color: #37414d;

        .logo-panel {
            padding: 0 20px;
            max-width: 600px;
            margin: 0 auto;
            display: flex;
            height: 100%;
            align-content: center;
            align-items: center;
        }
    }

    .header-info {
        max-width: 600px;
        margin: 0 auto;
        text-align: left;
        padding-left: 20px;
        font-weight: 600;
    }

    .main-page {
        max-width: 500px;
        text-align: center;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        width: 100%;
        height: 100%;
        border: 1px solid #eceaea;
        padding: 20px 15px;
        box-sizing: border-box;
        margin-top: 30px;

        .form-panel {
            .van-cell::after {
            }

            .pay-class {
                text-align: left;
                padding: 0 8px;

                .van-cell {
                    padding: 3px 16px;

                    .van-cell__title {
                        font-size: .8rem;
                        font-weight: 600;
                    }
                }
            }

            .tips-panel {
                margin: 20px 15px 0 15px;
                padding: 8px 8px;
                text-align: left;
                font-size: 12px;
                color: #888888;
                line-height: 25px;

                span {
                    display: block;
                }
            }

            .button-panel {
                margin: 20px 20px 0;
                font-weight: 700;

                span {
                    font-size: 14px;
                }

            }
        }

        .diy-dialog {
            z-index: 9;
            top: 35%;
            background-color: white;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px 8px 1px 8px;
            text-align: left;
            font-size: 14px;
            width: 70%;
            border-radius: 10px;
            left: 14.5%;

            .diy-dialog-buttons {
                padding: 0 15px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;
                height: 30px;

                a {
                    color: #1783d6;
                }

                div {
                    width: 100%;
                    text-align: center;
                }
            }

            .van-divider--hairline {
                margin: 8px 0;
            }
        }
    }
}
</style>
