<template>
    <div class="main-container">
        <van-form @submit="onSubmit" ref="submitForm">
            <div class="dialog-title">
                <div style="margin-left: 5px;">实名认证</div>
            </div>

            <div style="margin: 0 20px 10px;max-height: 250px;overflow-y: auto;">
                <div style="color: #aaaaaa;font-size: 12px;text-align: left">
                    根据国家相关法律法规要求，进入游戏需要实名认证
                </div>
                <van-field label-width="70px" label="真实姓名" label-class="inline-label"  placeholder="请输入真实姓名"
                           style="margin-top: 10px;color: #ffffff;" v-model="realName" clearable/>

                <van-field label-width="70px" label="身份证号" label-class="inline-label"  placeholder="请输入身份证号"
                           style="margin-top: 20px;color: #ffffff;" v-model="carId" clearable/>

                <div  class="box-button">
                    <van-button type="info" block native-type="submit" :loading="loading" size="small" round style="width: 160px;" loading-text="正在认证" color="#00adef">
                        立即认证
                    </van-button>

                    <div v-if="false" style="font-size: 12px;margin-top: 15px;display: flex;justify-content: flex-end;padding: 0 3px;">
                        <div @click="logout" style="color: #888888">切换其他账号</div>
                    </div>
                </div>

            </div>
        </van-form>
    </div>
</template>

<script>

    import {personCheck, logout} from '@/api/request'
    import {Toast} from 'vant'

    export default {
        name: 'realName',
        data: function () {
            return {
                realName: '',
                carId: '',
                loading: false,
                missShow: false,
                realNamePattern: /^[\u4E00-\u9FA5]{1,5}$/,
                carIdPattern: /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][012])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
            }
        },
        mounted() {

        },
        methods: {
            logout() {

            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                this.loading = true;
                let params = {
                    channelId: localStorage.getItem("channelId"),
                    gameId: localStorage.getItem("gameId"),
                    cardNo: this.carId,
                    realName: this.realName,
                };
                personCheck(params).then((res) => {
                    this.loading = false;
                    if (res.data.success === true) {
                        Toast.success('实名认证成功');
                        let user = JSON.parse(localStorage.getItem('user'));
                        user.isUserCheck = res.data.data;
                        localStorage.setItem('user', JSON.stringify(user));
                        this.miss();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            miss() {
                this.$parent.$parent.missRealName();
            },
            checkForm() {
                if (!this.realName) {
                    return "请输入真实姓名";
                }
                /*if (!this.realNamePattern.test(this.realName)) {
                    return "真实姓名格式错误";
                }*/
                if (!this.carId) {
                    return "请输入身份证号码";
                }
                if (!this.carIdPattern.test(this.carId)) {
                    return "身份证号码格式错误";
                }
                return "ok";
            },
        }
    }
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }

    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
    }

    /deep/.inline-label {
        //color: #aaaaaa !important;
    }

    .van-cell {
        padding: 8px 14px;
    }

    .van-field {
        background-color: #f3f3f3;
        border-radius: 8px;
    }

    /deep/ .van-field__control {
    }

    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
</style>
