<template>
    <div class="main-view">

        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                修改密码
            </div>
        </div>

        <van-form @submit="onSubmit" ref="submitForm" style="margin-top: 110px;">

            <div style="color: #888888;font-size: 14px;text-align: left;margin: 30px 25px 20px;">
                为了您的账号安全，请勿泄露密码给任何人。
            </div>

            <van-field label="原密码" label-width="80"  label-class="inline-label" type="password" placeholder="请输入您的原密码"
                       style="margin-top: 2px;padding: 16px 30px;font-size: 16px;" v-model="oldPassword" clearable/>

            <van-field  label="新密码" label-width="80"  label-class="inline-label" type="password" placeholder="请输入新密码"
                        style="margin-top: 10px;padding: 16px 30px;font-size: 16px;" v-model="newPassword" clearable/>

            <van-field label="确认密码" label-width="80"  label-class="inline-label" type="password" placeholder="请再次输入新密码"
                       style="margin-top: 10px;padding: 16px 30px;font-size: 16px;" v-model="confirmPassword" clearable/>

            <div class="box-button">
                <van-button type="info" block native-type="submit" round :loading="loading" style="width: 200px;font-size: 18px;"  loading-text=""
                            color="#00adef">
                    确认修改
                </van-button>
            </div>

        </van-form>


    </div>
</template>
<script>

import {Dialog, Toast} from "vant";
import crypto from "@/utils/crypto";
import { request, webTokenRequest} from "@/api/request";
import passwordIcon from "@/assets/password.png";
import backIcon from "@/assets/back.png";

export default {
        name: 'app-password',
        components: {
        },
        data() {
            return {
                passwordIcon,
                backIcon,
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,20}$/,
                loading: false,
            }
        },
        mounted() {
            this.refreshLoginInfo();
        },
        methods: {
            back() {
                this.$router.back();
            },
            refreshLoginInfo() {
                let options = {
                    url:"/web/getLoginInfo",
                    method: 'get'
                };
                webTokenRequest(options).then((res) => {
                    if (res.data.success === true) {
                        this.baseInfo = res.data.data;
                        localStorage.setItem('web_baseInfo', JSON.stringify(this.baseInfo));
                    } else {
                        Toast.fail('登录已失效，请重新登录');
                        localStorage.removeItem('web_baseInfo');
                        localStorage.removeItem('web_token');
                        this.back();
                    }
                });
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                this.loading = true;
                let options = {
                    url:"/web/modifyPasswordByOld",
                    data: {
                        oldPassword: crypto.encrypt(this.oldPassword),
                        newPassword: crypto.encrypt(this.newPassword),
                    }
                };

                webTokenRequest(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        Dialog.alert({
                            message: '密码修改成功，请重新登录!',
                        }).then(() => {
                            this.doLogout();
                        });
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            doLogout() {
                let options = {
                    url: "/web/logout",
                    data: {}
                };
                webTokenRequest(options).then(res => {
                    localStorage.removeItem('web_token');
                    localStorage.removeItem('web_baseInfo');
                    localStorage.removeItem('web_username');
                    localStorage.removeItem('web_headerImg');
                    this.back();
                });
            },
            checkForm() {
                if (!this.oldPassword) {
                    return "请输入原密码";
                }
                if (!this.passwordPattern.test(this.oldPassword)) {
                    return "原密码为6-20位数字字母特殊符号";
                }
                if (!this.newPassword) {
                    return "请输入新密码";
                }
                if (!this.passwordPattern.test(this.newPassword)) {
                    return "新密码为6-20位数字字母特殊符号";
                }
                if (!this.confirmPassword) {
                    return "请再次输入密码";
                }
                if (this.newPassword !== this.confirmPassword) {
                    return "两次密码输入不一致，请重新输入";
                }
                return "ok";
            },
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #ebf2f7;
    }
    .m-title {
        display: flex;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #ebf2f7;
        box-sizing: border-box;
    }

    /deep/.inline-label {
        color: #333333 !important;
    }
    .van-cell{
        padding: 8px 14px;
    }

    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
</style>
