<template>
    <div class="main-view" v-show="initFinished">

        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                绑定手机
            </div>
        </div>

        <van-form v-if="!isBind" @submit="onSubmit" ref="submitForm" style="margin-top: 110px;">
            <div style="color: #888888;font-size: 14px;text-align: left;margin: 30px 25px 20px;">
                为了账号安全，请及时绑定手机号码。
            </div>

            <van-field label="手机号码" label-width="80" label-class="inline-label" placeholder="请输入手机号" type="digit" maxlength="11"
                       style="margin-top: 2px;color: #ffffff;padding: 16px 30px;font-size: 16px;"  v-model="tel" clearable/>

            <van-field label="验证码" label-width="80" label-class="inline-label"  type="digit" maxlength="6"
                       style="margin-top: 10px;padding: 16px 30px;font-size: 16px;" v-model="verifyCode"  placeholder="请输入验证码">
                <template #button>
                    <van-count-down
                        v-show="showCountDown"
                        ref="countDown"
                        :time="60000"
                        :auto-start="false"
                        format="重新发送(ss秒)"
                        style="font-size: 14px;color: #888888"
                        @finish="finish"
                    />
                    <span style="color: #00adef;font-size: 14px;cursor: pointer;" v-show="!showCountDown" @click="sendSmsForBind">发送验证码</span>
                </template>
            </van-field>

            <div class="box-button">
                <van-button type="info" block native-type="submit" :loading="loading" round style="width: 200px;font-size: 18px;"
                            color="#00adef">
                    立即绑定
                </van-button>
            </div>
        </van-form>

        <van-form v-else @submit="onSubmitUnbind" ref="submitForm" style="margin-top: 110px;">
            <div style="color: #888888;font-size: 14px;text-align: left;margin: 30px 25px 20px;">
                当前账号已绑定手机
            </div>

            <van-field label="手机号码" label-width="80" label-class="inline-label" placeholder="请输入手机号"  maxlength="11"
                       style="margin-top: 2px;color: #ffffff;padding: 16px 30px;font-size: 16px;"  v-model="tel" readonly/>

            <div v-if="!showUnbind" style="text-align: right;margin: 10px 20px;">
                <span style="color: #aaaaaa;font-size: 14px;cursor: pointer;"  @click="showUnbind = true;">解绑该手机？</span>
            </div>

            <div v-else>
                <van-field  label="验证码" label-width="80" label-class="inline-label"  type="digit" maxlength="6"
                           style="margin-top: 10px;padding: 16px 30px;font-size: 16px;" v-model="verifyCode"  placeholder="请输入解绑验证码">
                    <template #button>
                        <van-count-down
                            v-show="showCountDown"
                            ref="countDown"
                            :time="60000"
                            :auto-start="false"
                            format="重新发送(ss秒)"
                            style="font-size: 14px;color: #888888"
                            @finish="finish"
                        />
                        <span style="color: #00adef;font-size: 14px;cursor: pointer;" v-show="!showCountDown" @click="sendSmsUnbind">发送验证码</span>
                    </template>
                </van-field>

                <div style="color: #aaaaaa;font-size: 14px;text-align: left;margin: 20px 25px 10px;">
                    <van-icon name="warning-o" style="margin-right: 6px;"/>当前操作会解绑手机号码，请谨慎操作
                </div>

                <div class="box-button">
                    <van-button type="info" block native-type="submit"  :loading="loading" round style="width: 200px;font-size: 18px;"
                                color="#00adef">
                        确认解绑
                    </van-button>
                </div>
            </div>


        </van-form>
    </div>
</template>
<script>

    import {Toast} from "vant";
    import {webTokenRequest} from "@/api/request";

    export default {
        name: 'app-tel',
        components: {
        },
        data() {
            return {
                tel: '',
                isBind: false,
                verifyCode: '',
                missShow: false,
                backShow: true,
                telPattern: /^1\d{10}$/,
                codePattern: /^\d{6}$/,
                showCountDown: false,
                showSendText: true,
                disabledSend: false,
                loading: false,
                showUnbind: false,
                initFinished: false,
            }
        },
        mounted() {
            this.refreshLoginInfo();
        },
        methods: {
            refreshLoginInfo() {
                let options = {
                    url:"/web/getLoginInfo",
                    method: 'get'
                };
                webTokenRequest(options).then((res) => {
                    if (res.data.success === true) {
                        this.baseInfo = res.data.data;
                        localStorage.setItem('web_baseInfo', JSON.stringify(this.baseInfo));
                        this.initPage();
                        this.initFinished = true;
                    } else {
                        Toast.fail('登录已失效，请重新登录');
                        localStorage.removeItem('web_baseInfo');
                        localStorage.removeItem('web_token');
                        this.back();
                    }
                });
            },
            initPage() {
                this.verifyCode = '';
                let baseInfo = JSON.parse(localStorage.getItem('web_baseInfo'));
                if (baseInfo) {
                    let phone = baseInfo.phone;
                    if (phone) {
                        this.isBind = true;
                        this.tel = phone;
                    }
                    else {
                        this.isBind = false;
                        this.tel = '';
                    }
                }
                this.showUnbind = false;
                this.finish();
            },
            back() {
                this.$router.back();
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                this.loading = true;
                let options = {
                    url:"/web/bindPhone",
                    data: {
                        phone: this.tel,
                        checkCode: this.verifyCode,
                    }
                };
                webTokenRequest(options).then((res) => {
                    this.loading = false;
                    if (res.data.success === true) {
                        Toast.success('手机号码绑定成功');
                        this.refreshLoginInfo();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            sendSmsForBind() {
                let ok = this.checkTel();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.disabledSend = true;
                this.showSendText = false;
                this.showCountDown = true;
                this.$refs.countDown.start();

                let options = {
                    url:"/web/sendSms",
                    data: {
                        phone: this.tel,
                        type: '2',
                    }
                };
                webTokenRequest(options).then((res) => {
                    if (res.data.success === true) {
                        Toast('验证码发送成功');
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            sendSmsUnbind() {
                this.disabledSend = true;
                this.showSendText = false;
                this.showCountDown = true;
                this.$refs.countDown.start();

                let options = {
                    url:"/web/checkAndSendSms?type=3",
                    method: 'get'
                };
                webTokenRequest(options).then((res) => {
                    if (res.data.success === true) {
                        if (res.data.data === '1') {
                            Toast('验证码发送成功');
                        }
                        else {
                            this.initPage();
                            Toast('当前账号未绑定手机');
                        }
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            onSubmitUnbind() {
                let ok = this.checkCode();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                let options = {
                    url:"/web/unBindPhone",
                    data: {
                        checkCode: this.verifyCode,
                    }
                };
                webTokenRequest(options).then((res) => {
                    if (res.data.success === true) {
                        Toast.success('手机号码解绑成功');
                        this.refreshLoginInfo();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            checkTel() {
                if (!this.tel) {
                    return "请输入手机号码";
                }
                if (!this.telPattern.test(this.tel)) {
                    return "请输入正确的手机号码";
                }
                return 'ok';
            },
            checkForm() {
                if (!this.tel) {
                    return "请输入手机号码";
                }
                if (!this.telPattern.test(this.tel)) {
                    return "请输入正确的手机号码";
                }
                if (!this.verifyCode) {
                    return "请输入验证码";
                }
                if (!this.codePattern.test(this.verifyCode)) {
                    return "验证码为6位数字";
                }
                return 'ok';
            },
            finish() {
                this.disabledSend = false;
                this.showSendText = true;
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            checkCode() {
                if (!this.verifyCode) {
                    return "请输入验证码";
                }
                if (!this.codePattern.test(this.verifyCode)) {
                    return "验证码为6位数字";
                }
                return 'ok';
            },
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #ebf2f7;
    }
    .m-title {
        display: flex;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #ebf2f7;
        box-sizing: border-box;
    }

    /deep/.inline-label {
        color: #333333 !important;
    }
    .van-cell{
        padding: 8px 14px;
    }
    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
</style>
