import serverArr from "@/api/servers";
import sdkUrl from "@/api/sdkurl";

const webConfig =  {
    //应用名称
    appName: "聚乐游戏",
    //应用名称
    gameAppName: "聚乐游戏",
    //应用图标
    logoUrl: require("@/assets/logo.png"),
    //圆形logo
    logoRoundUrl: require("@/assets/logo-round.png"),
    //微信公众号
    wechatUrl: "",
    //应用二维码
    appqrUrl: "",
    //icp备案
    icp: "辽ICP备2024045053号-1",
    //公安备案
    publicSecurity: "",
    //版权所有
    copyright: "copyright © 2024 julehuyu.com/ All Rights Resered.",
    //公司地址
    address: "辽宁省沈阳市辽中区蒲东街道滨河街19E号网点2",
    //公司名称
    companyName: "沈阳聚乐互娱游戏科技有限公司",
    //公司名称
    copyrightCompany: "聚乐游戏 版权所有",
    //用户协议
    agreement :  "https://julehuyu.com/static/agreement/user.html",
    //隐私政策
    privacy :  "https://julehuyu.com/static/agreement/privacy.html",
    //QQ客服
    qqkfList: [
        // {"info": "", "worktime": ""}
    ],
    wxkfList: [
        {"info": "https://work.weixin.qq.com/kfid/kfc4ce75bcc4fb85b3f", "worktime": "09:00-22:00"}
    ],
    telkfList: [
        // {"info": "", "worktime": ""}
    ],
    //平台币名称
    coinName: "聚乐币",
    bindCoinName: '聚玩币',
    //游戏加速器地址
    speedUrl: "https://julehuyu.com/static/speed/a.html?genCode=true",
    //sdkweb地址
    sdkUrl: sdkUrl,
    //默认搜索游戏名
    defaultSearchName: "神魂极速服",
    hotSearchName: ["斗罗大陆", "神魂极速服"],
    redirectUri: "https://julehuyu.com/g/#/selfPayWX",
    appid: "wx12c50dada46c0f20",
}

export default webConfig