<template>
    <div class="all-page">

        <home-nav ref="homeNavRef" class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                {{gameName}}
            </div>
        </div>

        <div class="p-title">
            <div style="height: 70px;padding-left: 30px;display: flex;align-items: center;color: #666666">
                <span>当前位置：</span>
                <span style="margin-left: 10px;">首页</span>
                <span style="margin-left: 10px;">></span>
                <span style="margin-left: 10px;">{{gameName}}</span>
            </div>
        </div>

        <div class="main-page">
            <div style="width: 100%; display: flex;justify-content: space-between;">
                <div class="game-info">
                    <img :src="logoUrl" class="game-icon-img">
                    <div class="game-text">
                        <span class="game-name-text" style="font-weight: bold;">{{gameName}}</span>
                        <div class="tags">
                            <span v-for="item in tagList">{{item}}</span>
                        </div>
                        <div class="topic-view" style="color: #888888">
                            <span>{{topic}}</span>
                            <span style="margin-left: 15px;color: #e56363">{{gameSize}}</span>
                        </div>
                    </div>
                    <div style="flex: 1"></div>

                    <div>
                        <div v-if="!!gameUrl" class="m-hidden-view p-play-button" @click="playGamePC">
                            在线玩
                        </div>
                        <div @click="downloadGame" class="download-button">
                            <span>游戏下载</span>
                        </div>
                    </div>

                </div>

                <div class="code-view">
                    <div v-if="!isM" class="code-div">
                        <div class="code-inner">
                            <div class="qr-code" ref="qrCodeUrl"></div>
                            <span style="font-size: 12px;color: #888888;margin-top: 10px">手机扫码下载游戏</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="game-desc">
                <div class="game-info-title">
                    游戏简介
                </div>
                <video v-if="!!videoUrl" :src="videoUrl" style="width: calc(100% - 20px); margin: 0 10px; object-fit:cover;border-radius: 5px;"
                       :poster="videoImgUrl" :controls="true" :autoplay="false"></video>
                <div style="font-size: 15px;margin: 10px 10px 10px;line-height: 28px;">
                    {{gameDesc}}
                </div>
            </div>

            <div class="game-desc bottom-view">
                <div class="game-info-title">
                    开服信息
                </div>
                <div v-if="openServerList.length > 0" style="font-size: 15px;margin: 10px 20px 10px;">
                    <div v-for="item in openServerList" style="height: 50px;font-size: 14px;border-bottom: 1px solid #f3f3f3;display: flex;align-items: center;padding: 0 3px;">
                        <span style="">{{item.server_name}}</span>
                        <span v-if="item.open !== 1" style="margin-left: 20px;color: #888888">{{item.open_time}}</span>
                        <span v-if="!!item.openInfo && item.open !== 1" style="margin-left: 20px;background-color: #ef580d;color: #ffffff;padding: 3px 4px;font-size: 12px;border-radius: 2px;">{{item.openInfo}}</span>
                        <span v-if="!!item.openInfo && item.open === 1" style="margin-left: 20px;border: 1px solid #ef580d;color: #ef580d;padding: 2px 3px;font-size: 12px;border-radius: 2px;">{{item.openInfo}}</span>
                    </div>
                </div>
                <div v-else  style="font-size: 13px;margin: 20px 10px 20px;color: #888888">
                    暂无开服信息
                </div>
            </div>


            <!--<div class="ios-div" style="" v-if="isM&&isSelfSigned" v-show="showInstall">点击允许后，打开手机设置->通用->VPN与设备管理，安装证书
            </div>-->

            <div v-if="!!gameUrl" class="m-bottom-view">
                <div class="play-button" @click="playGame">在线玩</div>
                <div class="m-button-right"  @click="downloadGame">{{buttonText}}</div>
            </div>

            <div v-else class="m-bottom-view">
                <div class="ios-div" @click="goGuide" v-if="!isSelfSigned&&downloading">iOS如何授权</div>
                <div class="m-button"  @click="downloadGame">{{buttonText}}</div>
            </div>

        </div>

        <van-overlay :show="overlayShow"  class-name="overlay-view">
            <div class="overlay-panel">
                <div>
                    <van-image :src="wjtImg" width="80px" height="80px"></van-image>
                </div>
                <div class="overlay-info">
                    <p>
                        请点击右上角 ... 在默认浏览器中打开
                    </p>
                </div>
            </div>
        </van-overlay>

        <div class="me-login-view" v-if="showLogin" @click.self="showLogin = false">
            <home-login ></home-login>
        </div>

        <home-footer class="m-footer m-hidden-view"></home-footer>
    </div>
</template>

<script>

    import downloadImg from '@/assets/downloadback/button_download.jpg'
    import guideImg from '@/assets/downloadback/button_guide.jpg'
    import logo from '@/assets/logo.png'
    import wjtImg from '@/assets/wjt.png'
    import QRCode from 'qrcodejs2'
    import {request, webTokenRequest} from '@/api/request'
    import {Toast, Dialog} from 'vant'

    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import homeTabbar from "@/components/home/HomeTabbar";
    import gzhCodeView from "@/components/home/GzhCodeView";
    import notice from "@/api/notice";
    import HomeLogin from "@/components/home/HomeLogin.vue";

    export default {
        name: 'download',
        components: {
            HomeLogin,
            homeNav,
            homeFooter,
            gzhCodeView,
            homeTabbar
        },
        data() {
            return {
                isSelfSigned: true,
                logoUrl: require("@/assets/logo-skeleton.png"),
                gameName: '',
                downloadImg,
                guideImg,
                wjtImg,
                logo,
                thisUrl: '',
                androidUrl: '',
                iosUrl: '',
                qrCodeShow: false,
                iosUrlToSet: 'https://julehuyu.com/static/download/toset.mobileprovision',
                isM: false,
                downloading: false,
                overlayShow: false,
                showInstall: false,
                gameId: '',
                channelId: '',
                gameSystem: '',
                iosSignType: '',
                videoUrl: null,
                videoImgUrl: '',
                tagList: [],
                gameDesc: '',
                buttonText: '游戏下载',
                topic: '',
                gameSize: '',
                gameUrl: '',
                from: '',
                isBoxApp: false,
                process: '',
                appStatus: 0,
                packageName: '',
                showLogin: false,
                openServerList: [],
            }
        },
        mounted() {
            this.from = localStorage.getItem('from');
            if (this.from === 'android_box') {
                this.isBoxApp = true;
            }
            console.log('gameDetail, from: ' + this.from);
            this.channelId = this.$route.path.split('/')[2];
            this.gameId = this.$route.path.split('/')[3];

            localStorage.setItem('channelId', this.channelId);
            /*if (!localStorage.getItem('channelId')) {
                localStorage.setItem('channelId', this.channelId);
            }*/

            if (!localStorage.getItem('udid')) {
                localStorage.setItem('udid', this.generateUUID());
            }

            let u = navigator.userAgent;
            //let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || /macintosh|mac|ipod/.test(u.toLowerCase()); //ios终端

            this.thisUrl = window.location.href
            this.isM = this.isMobile()
            if (!this.isM) {
                this.creatQrCode()
            }

            this.getDownLoad();
            if (this.isWeiXin()) {
                this.overlayShow = true
            }
            window.refreshProcess = this.refreshProcess;
            window.refreshAppInfo = this.refreshAppInfo;
        },
        methods: {
            back() {
                this.$router.back();
            },
            playGamePC() {
                if (!localStorage.getItem('web_token')) {
                    this.$refs.homeNavRef.openLoginPage();
                }
                else {
                    this.getUserCredential();
                }
            },
            playGame() {
                if (!localStorage.getItem('web_token')) {
                    this.showLogin = true;
                }
                else {
                    this.getUserCredential();
                }
            },
            getUserCredential() {
                let options = {
                    url:"/web/getUserCredential",
                    data: {
                        gameId: this.gameId
                    }
                };
                webTokenRequest(options).then((res) => {
                    if (res.data.success === true) {
                        let credential = res.data.data;
                        let params = '?credential=' + credential + "&from=" + this.from;
                        let hookUrl = 'https://app.xxh5.z7xz.com/login.php';
                        notice.playGame(this.gameUrl + params, hookUrl);
                        //notice.playGame('http://192.168.1.26:8080/#/h5/1/' + this.gameId + params, hookUrl);
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            resetPage() {
                this.showLogin = false;
            },
            refreshProcess(p) {
                this.process = p + "%";
                this.buttonText = "正在下载 (" + this.gameSize + ") " + p + "%";
                if (p === 100) {
                    this.buttonText = "下载完成";
                }
            },
            refreshAppInfo() {
                let appInfoStr = window.androidJs.getAppInstallInfo(this.packageName, this.androidUrl);
                //let appInfoStr = window.androidJs.getAppInstallInfo(this.packageName, "https://cdn.7li.club/eaoojlgn/1/shanhaijhxl.apk");
                let appInfo = JSON.parse(appInfoStr);
                this.appStatus = appInfo.status;
                if (this.appStatus === 0) {
                    this.buttonText = "游戏下载";
                }
                else if (this.appStatus === 1) {
                    this.buttonText = "已安装，点击打开";
                }
                else if (this.appStatus === 2) {
                    this.buttonText = "已下载，点击安装";
                }
            },
            getDownLoad() {
                let options = {
                    url:"/web/getGameInfo?id=" + this.gameId + "&adminId=" + this.channelId,
                    method: 'get',
                };

                request(options).then((res) => {
                    if (res.data.success === true) {
                        this.gameSystem = res.data.data.gameSystem
                        this.iosUrl = res.data.data.iosUrl
                        this.androidUrl = res.data.data.androidUrl
                        this.logoUrl = res.data.data.image_url
                        this.gameName = res.data.data.game_name
                        this.iosSignType = res.data.data.iosSignType
                        this.isSelfSigned = (res.data.data.iosSignType === 1)
                        this.videoUrl = res.data.data.videoUrl;
                        this.videoImgUrl = res.data.data.videoImgUrl;
                        this.tagList = res.data.data.tagList;
                        this.gameDesc = res.data.data.game_desc;
                        this.gameSize = res.data.data.game_size;
                        this.gameUrl = res.data.data.game_url;
                        this.topic = this.handlerTopic(res.data.data.game_topic);
                        this.openServerList = res.data.data.openServerList;
                        if (this.gameSize) {
                            this.buttonText = "游戏下载 (" + this.gameSize + ")"
                        }

                        if (this.isBoxApp) {
                            this.packageName = res.data.data.package_name;
                            let appInfoStr = window.androidJs.getAppInstallInfo(this.packageName, this.androidUrl);
                            //let appInfoStr = window.androidJs.getAppInstallInfo(this.packageName, "https://cdn.7li.club/eaoojlgn/1/shanhaijhxl.apk");
                            console.log('appInfo', appInfoStr);
                            let appInfo = JSON.parse(appInfoStr);
                            this.appStatus = appInfo.status;
                            if (this.appStatus === 1) {
                                this.buttonText = "已安装，点击打开";
                            }
                            else if (this.appStatus === 2) {
                                this.buttonText = "已下载，点击安装";
                            }
                            else if (this.appStatus === 3) {
                                //let p = appInfo.data;
                                //this.buttonText = "正在下载 (" + this.gameSize + ") " + p + "%";
                            }
                            else if (this.appStatus === 4) {
                                let p = appInfo.data;
                                this.buttonText = "已暂停 (" + this.gameSize + ") " + p + "%";
                            }
                        }
                    } else {
                        Toast(res.data.error.message)
                    }
                })
            },
            handlerTopic(t) {
                switch (t) {
                    case '1':
                        return '卡牌';
                    case '2':
                        return '传奇';
                    case '3':
                        return '武侠';
                    case '4':
                        return '角色';
                    case '5':
                        return '养成';
                    case '6':
                        return '三国';
                    case '7':
                        return '模拟';
                    case '8':
                        return '策略';
                    case '9':
                        return '休闲';
                }
            },
            isWeiXin() {
                let ua = window.navigator.userAgent.toLowerCase()
                //通过正则表达式匹配ua中是否含有MicroMessenger字符串
                return ua.match(/MicroMessenger/i) == 'micromessenger'
            },
            isMobile() {
                let browser = {
                    versions: function () {
                        let u = navigator.userAgent, app = navigator.appVersion;
                        return {
                            trident: u.indexOf('Trident') > -1, //IE内核
                            presto: u.indexOf('Presto') > -1, //opera内核
                            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
                            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
                            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                            iPad: u.indexOf('iPad') > -1, //是否iPad
                            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
                            weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
                            qq: u.match(/\sQQ/i) == " qq" //是否QQ
                        };
                    }(),
                    language: (navigator.browserLanguage || navigator.language).toLowerCase()
                }
                // return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                return browser.versions.mobile || browser.versions.android || browser.versions.ios;
            },
            creatQrCode() {
                //console.log(this.$refs.qrCodeUrl.innerHTML = "");
                this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.thisUrl,
                    width: 100,
                    height: 100,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H

                })
            },
            showDialog(msg) {
                Dialog.alert({
                    title: '提示',
                    messageAlign: 'left',
                    message: msg
                })
            },
            downloadGame() {
                let ua = navigator.userAgent.toLowerCase()
                if (this.isMobile()) {
                    if (/iphone|ipad|ipod/.test(ua)) {   //判断是否ios
                        if (this.gameId == 206 || this.gameId == 253) {
                            let msg = "点击允许后，找到 对应游戏文件 点击安装，输入锁屏密码，安装之后，在桌面进行查看";
                            this.showDialog(msg);
                            window.location.href = this.iosUrl;
                            this.downloading = true;
                        } else {
                            if (this.gameSystem === 1) {
                                Toast('当前游戏暂时不支持ios，请使用安卓手机进行游戏')
                                return
                            }
                            let msg = '';
                            if (this.isSelfSigned) {
                                msg = "点击允许后，再点击去安装证书，找到刚下载好的描述文件点击安装，输入锁屏密码"
                            } else {
                                msg = '点击允许安装后，在桌面查看下载进度，下载完毕之后设置信任，操作步骤查看ios授权示例';
                            }
                            Toast(msg);
                            this.showDialog(msg);
                            window.location.href = this.iosUrl
                            this.downloading = true
                        }
                        setTimeout(() => {
                            this.showInstall = true;
                        }, 2000)

                    } else {
                        if (this.gameSystem === 2) {
                            this.showDialog('当前游戏暂时不支持安卓，请使用ios系统进行游戏')
                            return
                        }
                        if (this.isBoxApp) {//游戏盒子下载游戏
                            window.androidJs.startDownload(this.androidUrl);
                            //window.androidJs.startDownload("https://cdn.7li.club/eaoojlgn/1/shanhaijhxl.apk");
                        }
                        else {
                            window.location.href = this.androidUrl
                        }
                    }
                } else {
                    if (/macintosh|mac|ipod/.test(ua)) {
                        if (this.gameSystem === 1) {
                            this.showDialog('当前游戏暂时不支持ios，请使用安卓手机进行游戏')
                            return
                        }
                        let msg = '';
                        if (this.isSelfSigned) {
                            msg = "点击允许后，再点击去安装证书，找到刚下载好的描述文件点击安装，输入锁屏密码"
                        } else {
                            msg = '点击允许安装后，在桌面查看下载进度，下载完毕之后设置信任，操作步骤查看ios授权示例';
                        }

                        this.showDialog(msg);
                        window.location.href = this.iosUrl
                        this.downloading = true
                    } else {
                        window.location.href = this.androidUrl
                    }

                }
            },
            goGuide() {
                this.$router.push({path: '/download/iosGuide'})
            },
            goSet() {
                window.location.href = this.iosUrlToSet;
            },
            generateUUID(){
                let d=new Date().getTime();
                let uuid ='xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                    let r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
        }
    }
</script>

<style scoped>
    .all-page {
        overflow: hidden;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background-color: #ebf2f7;
    }

    .main-page {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .game-info {
        background-color: #FFFFFF;
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 25px 35px;
        margin-top: 10px;
    }

    .p-title {
        width: 100%;
        max-width: 900px;
        height: 70px;
        background-color: #ffffff;
        border-radius: 8px;
        margin: 10px auto 0;
        z-index: 999;
        position: relative;
    }

    .tags {
        white-space: nowrap;
    }

    .tags > span {
        padding: 3px 5px;
        margin-right: 3px;
        background-color: #FDF1EB;
        color: #EC7338;
        font-size: 12px;
        text-align: left;
    }

    .game-name-text {
        font-size: 20px;
    }

    .game-icon-img {
        width: 120px;
        height: 120px;
    }

    .game-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100px;
        margin-left: 15px;
        overflow: hidden;
    }

    .game-desc {
        width: 100%;
        background-color: #FFFFFF;
        border-radius: 5px;
        margin-top: 10px;
        text-align: left;
        padding: 15px 10px;
        box-sizing: border-box;
        color: #333333;
        font-size: 13px;
    }

    .download-button {
        width: 150px;
        height: 45px;
        border-radius: 40px;
        background-color: #00adef;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
    }
    .overlay-view {
        z-index: 9999;
    }
    .m-bottom-view {
        height: 55px;
        position: fixed;
        bottom: 0;
        background-color: #ffffff;
        width: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .m-button {
        width: 80%;
        height: 40px;
        border-radius: 40px;
        background-color: #00adef;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
    }
    .m-button-right {
        width: 55%;
        height: 40px;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        background-color: #00adef;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
    }

    .play-button {
        width: 40%;
        height: 40px;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
        color: #00adef;
        border: 2px solid #00adef;
        user-select: none;
        cursor: pointer;
    }

    .p-play-button {
        width: 150px;
        height: 45px;
        border-radius: 40px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
        color: #00adef;
        border: 2px solid #00adef;
        user-select: none;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .ios-div {
        color: #1c88e7;
        font-size: 13px;
        padding: 0 10px;
        width: 100px;
    }

    .code-div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        margin-top: 10px;
        box-sizing: border-box;
    }

    .overlay-panel {
        text-align: right;
        margin-right: 15px;
        margin-top: 10px;
    }

    .overlay-info {
        text-align: center;
        width: 80%;
        margin: 20px auto;
        border-radius: 60px;
        border: 2px dashed white;
        color: white;
        font-weight: 600;
    }

    .code-view {
        margin-left: 10px;
        margin-top: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 0 15px;
    }
    .m-title {
        display: none;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #ebf2f7;
        box-sizing: border-box;
    }
    .me-login-view {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
    .bottom-view {
        margin-bottom: 20px;
    }
    .game-info-title {
        text-align: left;
        font-weight: bold;
        font-size: 25px;
        margin: 10px 10px;
        align-self: flex-start
    }
    @media screen and (max-width: 900px) {
        .game-info {
            width: 90%;
            margin: 0 auto;
            padding: 15px 20px;
        }
        .main-page {
            margin-top: 110px;
        }
        .game-name-text {
            font-size: 14px;
        }
        .topic-view {
            font-size: 14px;
            margin-top: 4px;
        }
        .game-icon-img {
            width: 80px;
            height: 80px;
        }
        .game-text {
            height: 75px;
        }
        .download-button {
            display: none;
        }
        .p-title {
            display: none;
        }
        .game-desc {
            width: 90%;
            border-radius: 8px;
            padding: 8px 10px;
        }
        .m-bottom-view {
            display: flex;
        }
        .code-view {
            display: none;
        }
        .m-hidden-view {
            display: none !important;
        }
        .m-title {
            display: flex;
        }
        .m-footer {
            margin-bottom: 50px;
        }
        .bottom-view {
            margin-bottom: 70px;
        }
        .game-info-title {
            font-size: 18px;
            margin: 5px 10px;
        }
    }
</style>
