import { render, staticRenderFns } from "./AppRealName.vue?vue&type=template&id=49d91d27&scoped=true"
import script from "./AppRealName.vue?vue&type=script&lang=js"
export * from "./AppRealName.vue?vue&type=script&lang=js"
import style0 from "./AppRealName.vue?vue&type=style&index=0&id=49d91d27&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d91d27",
  null
  
)

export default component.exports