const sdkUrlList = [
    {
        BASEURL: 'http://192.168.1.26:8081/f/#/sdk/main',
    },
    {
        BASEURL: 'https://julehuyu.com/f/#/sdk/main',
    },
];

let sdkUrlArr;

sdkUrlArr = sdkUrlList[1]
let sdkUrl = sdkUrlArr.BASEURL
export default sdkUrl
