<template>
    <div class="all-page">
        <home-nav ref="homeNavRef" class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                站内信
            </div>
        </div>

        <div class="p-title">
            <div style="height: 70px;padding-left: 30px;display: flex;align-items: center;color: #666666">
                <span>当前位置:</span>
                <span style="margin-left: 10px;">首页</span>
                <span style="margin-left: 10px;">></span>
                <span style="margin-left: 10px;">站内信</span>
            </div>
        </div>

        <div class="page-title">
            <div @click="showOpenNotice" class="page-title-item" :class="pageActive === 1 && 'title-active'">
                公开信
                <div :class="pageActive === 1 && 'title-active-line'"></div>
            </div>
            <div @click="showUserNotice" class="page-title-item" :class="pageActive === 2 && 'title-active'">
                我的私信
                <div :class="pageActive === 2 && 'title-active-line'"></div>
            </div>
        </div>
        <div class="main-view">
            <div v-show="pageActive === 1">
                <div @click="showDetailInfo(item)" v-for="item in openNoticeList" class="item-view">
                    <span style="color: #333333;"  class="one-line">{{item.title}}</span>
                    <div style="width: 100px;text-align: right;font-size: 13px;">
                        {{item.send_time}}
                    </div>
                </div>
            </div>
            <div v-show="pageActive === 2">
                <div v-if="isLogin">
                    <div v-if="userNoticeList.length > 0" @click="showDetailInfo(item)" v-for="item in userNoticeList" class="item-view">
                        <span style="color: #333333;" class="one-line">{{item.title}}</span>
                        <div style="width: 100px;text-align: right;font-size: 13px;">
                            {{item.send_time}}
                        </div>
                    </div>

                    <div v-if="userNoticeList.length === 0" style="font-size: 16px;margin-top: 100px;">
                        暂无内容~
                    </div>
                </div>

                <div v-else style="margin-top: 100px;font-size: 14px;color: #888888;">
                    您还未登录，请在<span @click="openLogin" style="margin: 0 5px;font-weight: bold;color: #00adef;cursor: pointer;user-select: none;">登录</span>之后查看私信
                </div>
            </div>
        </div>

        <div class="me-login-view" v-if="showLogin" @click.self="showLogin = false">
            <home-login ></home-login>
        </div>

        <div class="me-login-view" v-if="showNoticeDetail" @click.self="showNoticeDetail = false">
            <div class="detail-main">
                <div class="scroll-view">
                    <div class="detail-title">
                        {{noticeDetailInfo.title}}
                    </div>
                    <div style="width: 100%;text-align: right;font-size: 13px;color: #888888;">
                        {{noticeDetailInfo.send_time}}
                    </div>
                    <div class="detail-content">
                        {{noticeDetailInfo.content}}
                    </div>
                </div>

                <div @click="showNoticeDetail = false" style="font-size: 16px;font-weight: bold;color: #00adef;line-height: 50px;cursor: pointer;user-select: none">
                    已阅
                </div>
            </div>
        </div>

        <home-tabbar></home-tabbar>
        <home-footer class="m-hidden-view"></home-footer>
    </div>
</template>

<script>
    import {Toast, Dialog} from 'vant'

    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import gzhCodeView from "@/components/home/GzhCodeView";
    import homeTabbar from "@/components/home/HomeTabbar";
    import {request, webTokenRequest} from "@/api/request";
    import HomeLogin from "@/components/home/HomeLogin.vue";

    export default {
        name: "Notice",
        components: {
            HomeLogin,
            homeNav,
            homeFooter,
            gzhCodeView,
            homeTabbar
        },
        data() {
            return {
                openNoticeList: [],
                userNoticeList: [],
                pageActive: 1,
                isLogin: false,
                showLogin: false,
                showNoticeDetail: false,
                noticeDetailInfo: {},
            }
        },
        mounted() {
            this.isLogin = !!localStorage.getItem('web_token');
            this.queryOpenNotice();
            if (this.isLogin) {
                this.queryUserNotice();
            }
        },
        methods: {
            back() {
                this.$router.back();
            },
            showDetailInfo(item) {
                this.noticeDetailInfo = item;
                this.showNoticeDetail = true;
            },
            openLogin() {
                this.showLogin = true;
            },
            showOpenNotice() {
                this.pageActive = 1;
            },
            showUserNotice() {
                this.pageActive = 2;
            },
            resetPage() {
                this.showLogin = false;
                this.isLogin = true;
                this.queryUserNotice();
            },
            queryOpenNotice() {
                let options = {
                    url: "/web/getOpenNotice",
                    method: 'get',
                };
                request(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        this.openNoticeList = res.data.data;
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            queryUserNotice() {
                let options = {
                    url: "/web/getUserNotice",
                    method: 'get',
                };
                webTokenRequest(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        this.userNoticeList = res.data.data;
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .detail-main {
        width: 400px;
        height: 350px;
        margin: 50px auto 0;
        background-color: #ffffff;
        border-radius: 12px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .scroll-view {
        height: 300px;
        overflow-y: auto;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .scroll-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }

    .detail-title {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin-top: 15px;
    }
    .detail-content {
        font-size: 14px;
        color: #444444;
        text-align: left;
        margin-top: 15px;
    }
    .all-page {
        overflow: hidden;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background-color: #ebf2f7;
    }
    .p-title {
        width: 100%;
        max-width: 900px;
        height: 70px;
        background-color: #ffffff;
        border-radius: 8px;
        margin: 20px auto 0;
        z-index: 999;
        position: relative;
    }
    .me-login-view {
        position: absolute;
        padding-top: 80px;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }

    .m-title {
        display: none;
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        position: fixed;
        align-items: flex-end;
        padding: 0 20px;
        z-index: 999;
        background-color: #ebf2f7;
        box-sizing: border-box;
    }
    .main-view {
        width: 100%;
        max-width: 900px;
        margin: -10px auto 20px;
        background-color: #ffffff;
        border-radius: 10px;
        color: #888888;
        font-size: 18px;
        min-height: 300px;
        padding: 10px 30px;
        box-sizing: border-box;
    }
    .page-title {
        width: 100%;
        max-width: 900px;
        margin: 20px auto 0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #666666;
        font-weight: bold;
        font-size: 16px;
    }
    .title-active-line {
        width: 20px;
        height: 3px;
        background-color: #00adef;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .page-title-item {
        padding: 10px 25px 30px;
        cursor: pointer;
        user-select: none;
        position: relative;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .title-active {
        color: #00adef;
        background-color: #ffffff;
    }
    .item-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 40px;
        background-color: #f7f7f7;
        margin-top: 8px;
        border-radius: 8px;
        border: 1px solid #f2f7fa;
        cursor: pointer;
    }
    .one-line {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
    }

    @media screen and (max-width: 900px) {
        .m-hidden-view {
            display: none !important;
        }
        .p-title {
            display: none;
        }
        .m-title {
            display: flex;
        }
        .main-view {
            width: 90%;
            padding: 10px 10px;
        }
        .me-login-view {
            padding-top: 0;
        }
        .page-title {
            width: 90%;
            margin: 110px auto 0;
            font-size: 14px;
        }
        .page-title-item {
            padding: 7px 15px 17px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .title-active-line {
            bottom: 10px;
        }
        .detail-main {
            width: 90%;
            height: 350px;
            margin: 110px auto 0;
        }
        .item-view {
            padding: 10px 15px;
            font-size: 14px;
        }
    }
</style>