<template>
    <div class="main">
        <div class="game-main">
            <iframe v-show="true" allowfullscreen ref="gameIframe" id="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%"
                    height="100%"></iframe>
        </div>

        <div  class="float-icon" id="moveDiv" :class="isChangeIconSize && 'change-icon-size'"
             @mousedown="down($event)"
             @touchstart="down($event)"
             @mousemove="move($event)"
             @touchmove="move($event)"
             @mouseover="end($event)"
             @mouseleave="move($event)"
             @mouseup="end($event)"
             @touchend="end($event)"
             @click="showMainWindow">

            <img :src="logoRoundUrl" @click="showMainDialog" style="border-radius: 20px;cursor: pointer;width: 50px;height: 50px;">

        </div>

        <van-popup v-model="mainDialogShow"  class="main-dialog" >
            <div @click="exit" style="font-size: 12px;margin-left: 10px;color: #f66969;cursor: pointer;position: absolute;right: 40px;top: 5px;padding: 3px 10px;">退出游戏</div>

            <div style="padding: 15px 20px;">
                <div style="display: flex;align-items: center">
                    <img :src="logoRoundUrl" style="width: 40px;height: 40px;">
                    <div style="display: flex;flex-direction: column;align-items: flex-start;padding-left: 10px">
                        <div style="color: #5e5e5e;font-size: 16px;">
                            加速器
                        </div>
                        <div style="color: #5e5e5e;margin-top: 4px;font-size: 12px;">当前通行证: <span style="user-select: text">{{code || '获取失败'}}</span>
                            <span @click="doCopy" v-if="code !== ''" style="margin-left: 10px;border: 1px solid #00adef;padding: 3px 8px;color: #00adef;border-radius: 3px;font-weight: bold;cursor: pointer">复制</span>
                        </div>
                    </div>
                </div>

                <svg @click="mainDialogShow = false" style="width: 30px;height: 30px;position: absolute;right: 1px;top: 1px;cursor: pointer" t="1720877097840" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4174" id="mx_n_1720877097841" width="64" height="64"><path d="M512 466.944l233.472-233.472a31.744 31.744 0 0 1 45.056 45.056L557.056 512l233.472 233.472a31.744 31.744 0 0 1-45.056 45.056L512 557.056l-233.472 233.472a31.744 31.744 0 0 1-45.056-45.056L466.944 512 233.472 278.528a31.744 31.744 0 0 1 45.056-45.056z" fill="#8a8a8a" p-id="4175"></path></svg>
            </div>
            <div style="width: 100%;height: 1px;background-color: #868686;transform: scaleY(.3)"></div>
            <div style="display: flex;justify-content: space-between;margin-top: 30px;padding: 0 40px;user-select: none">
                <div @click="setSpeed(0.2)" class="speed-button" :class="speedRate === 0.2 && 'speed-button-active'">
                    X0.2
                </div>
                <div @click="setSpeed(0.5)" class="speed-button" :class="speedRate === 0.5 && 'speed-button-active'">
                    X0.5
                </div>
                <div @click="setSpeed(1)" class="speed-button" :class="speedRate === 1 && 'speed-button-active'">
                    X1
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;margin-top: 20px;padding: 0 40px;">
                <div @click="setSpeed(2)" class="speed-button" :class="speedRate === 2 && 'speed-button-active'">
                    X2
                </div>
                <div @click="setSpeed(5)" class="speed-button" :class="speedRate === 5 && 'speed-button-active'">
                    X5
                </div>
                <div @click="setSpeed(10)" class="speed-button" :class="speedRate === 10 && 'speed-button-active'">
                    X10
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;margin-top: 20px;padding: 0 40px;">
                <div @click="setSpeed(15)" class="speed-button" :class="speedRate === 15 && 'speed-button-active'">
                    X15
                </div>
                <div @click="setSpeed(20)" class="speed-button" :class="speedRate === 20 && 'speed-button-active'">
                    X20
                </div>
                <div @click="setSpeed(30)" class="speed-button" :class="speedRate === 30 && 'speed-button-active'">
                    X30
                </div>
            </div>

        </van-popup>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import { Dialog, Toast } from 'vant'
    import myloading from '@/components/myloading'
    import timerhook from '@/utils/timerhook';
    import {get, request} from "@/api/request";
    import webConfig from "@/api/config";

    export default {
        name: "login",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                mainDialogShow: false,
                gameUrl: '',

                position: {x: 0, y: 0},
                nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',
                speedRate: 1,
                code: '',
                isChangeIconSize: true,
                logoRoundUrl: '',
            }
        },
        mounted() {
           this.initPage();
            this.checkIframeParam();
            window.addEventListener('message', this.listener);
        },
        methods: {
            initPage() {
                this.logoRoundUrl = webConfig.logoRoundUrl;
                this.gameUrl = webConfig.speedUrl;
                
                setTimeout(()=> {
                    this.isChangeIconSize = false;
                }, 5000);
                timerhook.setSpeed(1);
                
            },
            setSpeed(n) {
                this.getAndCopyCode();
                this.speedRate = n;
                timerhook.setSpeed(n);
                Toast('游戏当前加速倍率：' + n);
            },
            showMainDialog() {
                this.mainDialogShow = !this.mainDialogShow;
                if (this.mainDialogShow) {
                    this.getAndCopyCode();
                }
            },
            exit() {
                Dialog.confirm({
                    message: '确认退出游戏吗？',
                }).then(() => {
                    localStorage.removeItem('iframe_param');
                    window.location.reload();
                }).catch(() => {
                });
            },
            listener(e) {
                if(e.data.type === 'linkParam'){
                    this.saveIframeParam(e);
                }
            },
            saveIframeParam(e) {
                let url = this.gameUrl;
                let param = e.data.params;
                if(!param){
                    this.changeIframeUrl(url);
                    return;
                }
                if(param === "?genCode=true") return;

                let iframeParam = JSON.parse(localStorage.getItem('iframe_param'));
                if(iframeParam){

                }else {
                    let currentTimestamp = Date.now();
                    let gapTimestamp = 60 * 60 * 1000 * 2.5;

                    let params = {
                        expired: currentTimestamp + gapTimestamp,
                        params: param
                    }
                    localStorage.setItem('iframe_param',JSON.stringify(params));
                }
            },
            changeIframeUrl(url) {
                this.$nextTick(() => {
                    this.$refs.gameIframe.src = url;
                })
            },
            checkIframeParam() {
                let interval = setInterval(() => {
                    if(this.$refs.gameIframe) {
                        let iframeParam = JSON.parse(localStorage.getItem('iframe_param'));
                        
                        if(iframeParam){
                            if(iframeParam.expired < Date.now()){
                                localStorage.removeItem('iframe_param');
                            }else
                            if (iframeParam.params) {
                                let url = this.gameUrl.substring(0,this.gameUrl.indexOf('?'));
                                this.changeIframeUrl(url + iframeParam.params)
                            }
                        }

                        window.clearInterval(interval);
                    }
                } ,100)
            },
            getIsIOS() {
                return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 检测是否为ios
            },
            doCopy() {
                if (this.getIsIOS()) {
                    this.copyCodeForIOS(this.code);
                }
                else {
                    this.copyCode(this.code);
                }
            },
            copyCodeForIOS(value, type = 'input') {
                const input = document.createElement(type);
                input.setAttribute('readonly', 'readonly'); // 设置为只读, 防止在 ios 下拉起键盘
                input.value = value;
                document.body.appendChild(input);
                input.setSelectionRange(0, 9999); // 防止 ios 下没有全选内容而无法复制
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
                Toast('通行证复制成功');
            },
            copyCode(code) {
                const input = document.createElement('input')
                document.body.appendChild(input)
                input.setAttribute('value', code)
                input.select()
                if (document.execCommand('copy')) {
                    document.execCommand('copy')
                }
                document.body.removeChild(input);
                Toast('通行证复制成功');
            },
            getAndCopyCode() {
                try {
                    const iframe = document.getElementById('gameIframe');
                    const doc = iframe.contentDocument || iframe.contentWindow.document;
                    let div = doc.getElementById("qrcode");
                    if (!!div) {
                        this.code = div.title;
                        //this.copyCode(this.code);
                    }
                    else {
                        this.code = '';
                    }
                } catch (e) {
                    this.code = '';
                }
            },
            down(event) {
                this.flags = true;
                let touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.position.x = touch.clientX;
                this.position.y = touch.clientY;
                this.dx = moveDiv.offsetLeft;
                this.dy = moveDiv.offsetTop;
            },
            move(event) {
                if (this.flags) {
                    if (this.buttonHidden) {
                        this.activeButton();
                    }
                    let touch;
                    if (event.touches) {
                        touch = event.touches[0];
                    } else {
                        touch = event;
                    }
                    this.nx = touch.clientX - this.position.x;
                    this.ny = touch.clientY - this.position.y;
                    this.xPum = this.dx + this.nx;
                    this.yPum = this.dy + this.ny;
                    moveDiv.style.left = this.xPum + "px";
                    moveDiv.style.top = this.yPum + "px";
                    //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
                    document.addEventListener("touchmove", function () {
                        event.preventDefault();
                    }, false);
                }
            },
            //鼠标释放时候的函数
            end(event) {
                this.flags = false;
                if (!this.xPum) {
                    return;
                }

                let {
                    clientHeight: windowHeight,
                    clientWidth: windowWidth
                } = document.documentElement;
                // 计算后坐标  设置 按钮位置
                //this.halfOffset = -moveDiv.clientWidth / 2;
                if (this.yPum > 50 && this.yPum < (windowHeight - 150)) { //  不在顶部 且 不在底部
                    if (this.xPum <= (windowWidth / 2)) {  //  left 小于等于屏幕一半
                        moveDiv.style.left = 0;
                        moveDiv.style.right = 'auto';
                        //this.hiddenOnLeft();
                        this.start();
                    } else { //  left 大于屏幕一半
                        moveDiv.style.left = 'auto';
                        moveDiv.style.right = 0;
                        this.start();
                    }
                } else {
                    if (this.yPum <= 50) {  //  在顶部
                        moveDiv.style.top = 0;
                        moveDiv.style.bottom = 'auto';
                        this.start();
                    } else if (this.yPum >= (windowHeight - 150)) {
                        moveDiv.style.bottom = 0;
                        moveDiv.style.top = 'auto';
                        this.start();
                    }
                    if (this.xPum < 0) {
                        moveDiv.style.left = 0;
                        this.start();
                    } else if (this.xPum > (windowWidth - moveDiv.clientWidth)) {
                        moveDiv.style.right = 0;
                        moveDiv.style.left = 'auto';
                        this.start();
                    }
                }
            },
            start() {
            },
            showMainWindow() {

            }
        }
    }
</script>

<style scoped>
    .main-dialog {
        width: 360px;
        height: 300px;
        border-radius: 5px;
        user-select: none;
    }

    .main {
        width: 100%;
        height: 100vh;
        background-color: #e8e8e8;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        overflow: hidden;
    }
    .game-main{
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
    }

    .main-center{
        max-width: 600px;
        height: calc(100vh - 80px);
        margin: 0 auto;
        background-color: #FFFFFF;
        box-sizing: border-box;
        padding-top: 50px;
        overflow-y: auto;
    }

    .tips-view {
        width: 80%;
        margin: 20px auto;
        display: flex;flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding: 10px 0;
        font-size: 12px;
        color: #666666;
        box-sizing: border-box;
    }
    .tips-view span {
        margin-top: 5px;
    }
    .speed-button {
        font-weight: bold;
        width: 60px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        color: #ffffff;
        background-color:  #00adef;
        border: 2px solid #00adef;
        border-radius: 30px;
        text-align: center;
        cursor: pointer;
    }
    .speed-button-active {
        color: #00adef;
        background-color: unset;
    }
    .van-field{
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
        padding: 6px 16px;
    }
    .van-field:focus-within{
        outline: 2px #F18F25 solid;
    }
    /deep/.van-field__control {
        color: #F18F25;
    }

    .float-icon {
        position: fixed;
        touch-action: none;
        height: 50px;
        width: 50px;
        /* 如果碰到滑动问题，1.3 请检查 z-index。z-index需比web大一级*/
        z-index: 999;
        top: 30%;
        left: 0;
    }

    @keyframes changeIconSize {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.4);
        }
        100% {
            transform: scale(1);
        }
    }

    .change-icon-size {
        animation: changeIconSize 1s infinite alternate;
    }
</style>